@import "variables";

.navBarContainer {
  background-color: $main-green;
  width: 100%;
  height: 64px;
  box-shadow: 0px 3px 4px #00000029;
  display: flex;
  align-items: center;
  justify-content: space-between;
}