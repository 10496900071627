@import 'variables';

.ingredientElementContainer {
  width: 100%;
  padding: 30px 16px;
  border-top: 1px solid #E0E0E0;
  &:last-child {
    border-bottom: 1px solid #E0E0E0;
  }
  .info {
    display: flex;
    align-items: center;
    .quantity {
      margin-left: auto;
    }
    .deleteIcon {
      color: $main-red;
      margin-left: 16px;
    }
  }
  .editIngredient {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    .quantity {
      display: flex;
      align-items: center;
      button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $main-blue;
        border: none;
      }
      input {
        margin: 0 16px;
      }
    }
    input {
      width: 40px;
    }
  }
}