@import './color';

.fasteryHeader {
    font-size: 30px;
    color: $medium-font;
    font-weight: 500;
  }

.fasterySubtitle {
    font-size: 12px;
    color: $light-font;
}

.emptySubtitle {
    display: flex;
    font-size: 18px;
    color: $light-font;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
}