$main-red: #EA5C57;
$main-blue: #A3C5E7;
$main-green: #48775C;
$main-yellow: #F6C57E;

$background-color: #FAFDFF;

// Fonts
$medium-font: #585858;
$light-font: #7E7E7E;
$ultra-light-font: #B2B2B2;