@import 'variables';

.ingredientsContainer {
  width: 100%;
  padding: 16px;
  background-color: $background-color;
  h2 {
    color: $medium-font;
    font-weight: 400;
    font-size: 30px;
  }
  .subtitle {
    font-size: 12px;
    color: $light-font;
  }
  .droppable {
    height: 8px;
    &:-moz-drag-over {
      background-color: blue;
      height: 40px;
    }
  }
  .ingredientElement {
    margin-bottom: 2px;
    background-color: #FFF;
    border-radius: 8px;
    padding: 4px 16px;
    .name {
      margin-left: 16px;
    }
  }
}