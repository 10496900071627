@import 'variables';

.stepTwoContainer {
  h2 {
    color: $medium-font;
    font-weight: 400;
    font-size: 30px;
  }
  .subtitle {
    font-size: 12px;
    color: $light-font;
  }
  .ingredientList {
    width: 100%;
  }
}