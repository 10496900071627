@import 'variables';

.groceryCardContainer {
  margin: 16px 8px;
  .insideCard {
    padding: 32px 16px;
    span {
      color: $light-font;
    }
  }
}
a {
  color: $medium-font;
}