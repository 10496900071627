@import 'variables';

.recipeCardContainer {
  width: calc(50% - 8px);
  margin-top: 16px;
}
a {
  text-decoration: none !important;
}
.insideCard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .imgContainer {
    width: 100%;
    height: 150px;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .text {
    padding: 8px;
    font-size: 15px;
    width: 100%;
    h2 {
      color: $medium-font;
      font-size: 12px;
    }
    .time {
      color: $light-font;
      font-size: 12px;
    }
  }
}