.createRecipeContainer {
  padding: 16px;
  form {
    margin-top: 16px;
    .formGroup {
      margin-top: 16px;
      margin-bottom: 100px;
      h3 {
        margin-bottom: 8px;
      }
      .ingredientRow {
        display: flex;
        margin-top: 16px;
        .autoComplete {
          min-width: 150px;
          height: 50px;
        }
        .quantity {
          margin-left: 16px;
        }
      }
    }
  }
}