.recipeContainer {
  width: 100%;
  .recipeSearch {
    width: 75%;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 32px;
    position: relative;
    input {
      width: 100%;
      max-width: 350px;
      height: 43px;
      border-radius: 8px;
      border: none;
      box-shadow: 0px 4px 8px #00000029;
      padding: 0 16px 0 16px;
      &:focus {
        outline: none;
      }
    }
    .closeIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .recipeGrid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 100px;
    margin-top: 24px;
  }
}