@import "variables";

.footerContainer {
  padding: 8px;
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  height: 100px;
  .fade {
    height: 20px;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, $background-color 100%) 0% 0% no-repeat padding-box;;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: $background-color;
    Button {
      background-color: $main-yellow;
      border-radius: 24px;
      color: #FFF;
      box-shadow: none;
      height: 40px;
    }
    .number {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      background-color: $main-blue;
    }
  }
}