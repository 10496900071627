@import 'variables';

.cardContainer {
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.04);
}

.selected {
  outline: 2px solid $main-yellow;
}