@import 'variables';

.stepOneContainer {
  width: 100%;
  h2 {
    color: $medium-font;
    font-weight: 400;
    font-size: 30px;
  }
  .subtitle {
    font-size: 12px;
    color: $light-font;
  }
  form {
    width: 100%;
    .textField {
      width: 100%;
    }
    .nextStepButton {
      position: fixed;
      bottom: 16px;
      right: 16px;
      background-color: $main-yellow;
      color: #FFF;
    }
    .img {
      margin-top: 32px;
      width: 100%;
      height: 381px;
      background-color: #F0F0F0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      color: $medium-font;
      div {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .coverImage {
          width: 100%;
          border-radius: 16px;
        }
      }
    }
  }
}

.input {
  width: 0;
  height: 0;
  opacity: 0;
}