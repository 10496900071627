@import 'variables';

.groceryContainer {
  width: 100%;
  padding: 16px;
  section {
    border-top: 1px solid #E0E0E0;
    padding-top: 24px;
    padding-bottom: 60px;
    &:first-child {
      border: none;
      padding-top: 0;
    }
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .list {
      margin-top: 16px;
      .ingredientContainer {
        padding: 4px 16px;
        border-top: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        width: 100%;
        .name {
          color: $medium-font;
          font-size: 15px;
          font-weight: 500;
        }
        .quantity {
          margin-left: auto;
          margin-right: 16px;
        }
        &:last-child {
          border-bottom: 1px solid #E0E0E0;
        }
        .checkbox {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

}